import React, { Fragment, useCallback, useMemo, useState } from "react";
import type { StatsByNutrient } from "../../../../../@types";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, IconButton } from "@mui/joy";
import { Sheet, Table } from "@mui/joy";

interface StatsTableProps {
  stats: StatsByNutrient[];
}

export const StatsTable: React.FC<StatsTableProps> = ({ stats }) => {
  const [openId, setOpenId] = useState<number | null>(null);
  const [sortColumn, setSortColumn] = useState<keyof StatsByNutrient | null>(
    null
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const onSortChange = useCallback(
    (column: keyof StatsByNutrient) => {
      if (sortColumn === column) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortDirection("asc");
      }
    },
    [sortColumn, sortDirection]
  );

  const sortedStats = useMemo(() => {
    return stats?.sort((first, second) => {
      if (!sortColumn) return 0;

      if (first[sortColumn] < second[sortColumn]) {
        return sortDirection === "asc" ? -1 : 1;
      }

      if (first[sortColumn] > second[sortColumn]) {
        return sortDirection === "asc" ? 1 : -1;
      }

      return 0;
    });
  }, [sortColumn, sortDirection, stats]);

  const memoizedTable = useMemo(() => {
    return (
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: 4,
          "--TableCell-height": "40px",
          "--TableHeader-height": "calc(1 * var(--TableCell-height))",
          "--Table-firstColumnWidth": "80px",
          "--Table-lastColumnWidth": "144px",
          "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
          "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
          overflow: "auto",
          background: (
            theme
          ) => `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
          backgroundSize:
            "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "local, local, scroll, scroll",
          backgroundPosition:
            "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
          backgroundColor: "background.surface",
        }}
      >
        <Table
          borderAxis="bothBetween"
          stripe="odd"
          hoverRow
          sx={{
            "& tr > *:first-of-type": {
              position: "sticky",
              left: 0,
              boxShadow: "1px 0 var(--TableCell-borderColor)",
              bgcolor: "background.surface",
            },
            "& tr > *:last-child": {
              position: "sticky",
              right: 0,
              bgcolor: "var(--TableCell-headBackground)",
            },
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 25 }}>
                <button onClick={() => onSortChange("id")}>Id</button>
              </th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("normalizedScore")}>
                  Normalized Score
                </button>
              </th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("absoluteScore")}>
                  Absolute Score
                </button>
              </th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("dosageWeighted")}>
                  Dosage Weighted
                </button>
              </th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("biologicalForm")}>
                  Biological Form
                </button>
              </th>
              <th aria-label="last" style={{ width: 80 }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedStats?.length ? (
              sortedStats?.map((row) => (
                <Fragment key={row.id}>
                  <tr>
                    <td className="text-xs">
                      {String(row.id).padStart(3, "0")}
                    </td>
                    <td className="text-sm">{row.normalizedScore}</td>
                    <td className="text-sm">{row.absoluteScore}</td>
                    <td className="text-sm">{row.dosageWeighted}</td>
                    <td className="text-sm">{row.biologicalForm}</td>
                    <td>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <IconButton
                          size="sm"
                          color="neutral"
                          onClick={() =>
                            setOpenId((prevOpenId) =>
                              row.id === prevOpenId ? null : row.id
                            )
                          }
                        >
                          {row.id === openId ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                  {row.id === openId && (
                    <tr>
                      <td colSpan={6}>
                        <Box sx={{ display: "flex", gap: 1, p: 1 }}>
                          <ul>
                            {row.metabolites.map((metabolite) => {
                              return (
                                <li key={metabolite.id}>
                                  {metabolite.name} - {metabolite.score} -{" "}
                                  {metabolite.level}
                                </li>
                              );
                            })}
                          </ul>
                        </Box>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <Box>
                    <p>Not found stats for this nutrient</p>
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Sheet>
    );
  }, [onSortChange, openId, sortedStats]);

  return memoizedTable;
};
