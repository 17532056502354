import { CssVarsProvider } from "@mui/joy/styles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouterDashboardLayout from "./pages/dashboard/routerLayout";
import MetabolitePage from "./pages/dashboard/sidebar/metabolite/page";
import RecipientsPage from "./pages/dashboard/sidebar/recipients/page";
import ResultsPage from "./pages/dashboard/sidebar/results/page";
import LoginPage from "./pages/login/page";
import { AuthProvider } from "./store/contexts/auth.context";
import { MetaboliteProvider } from "./store/contexts/metabolite.context";
import { RecipientProvider } from "./store/contexts/recipient.context";
import { ResultsProvider } from "./store/contexts/results.context";
import { ReduxProvider } from "./store/provider";
import { io } from "socket.io-client";
import { StatsByNutrient } from "./pages/dashboard/sidebar/statsByNutrient/page";
import { StatsByNutrientProvider } from "./store/contexts/statsByNutrient.context";

export const socket = io(process.env.REACT_APP_API_URL as string);

function App() {
  // const router = createBrowserRouter(
  //   createRoutesFromElements(
  //     <Route path="/" element={<RouterRoot />}>
  //       <Route index element={<LoginPage />} />
  //       <Route path="/dashboard" element={<DashboardPage />} />
  //     </Route>
  //   )
  // );

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/dashboard",
      element: <RouterDashboardLayout />,
      children: [
        // {
        //   path: "",
        //   element: <DashboardPage />,
        // },
        {
          path: "/dashboard/metabolites",
          element: <MetabolitePage />,
        },
        {
          path: "/dashboard/recipients",
          element: <RecipientsPage />,
        },
        {
          path: "/dashboard/results",
          element: <ResultsPage />,
        },
        {
          path: "/dashboard/stats-by-nutrient",
          element: <StatsByNutrient />,
        },
      ],
    },
  ]);

  return (
    <CssVarsProvider>
      <ReduxProvider>
        <ToastContainer position="bottom-right" />
        <AuthProvider>
          <MetaboliteProvider>
            <RecipientProvider>
              <ResultsProvider>
                <StatsByNutrientProvider>
                  <RouterProvider router={router} />
                </StatsByNutrientProvider>
              </ResultsProvider>
            </RecipientProvider>
          </MetaboliteProvider>
        </AuthProvider>
      </ReduxProvider>
    </CssVarsProvider>
  );
}

export default App;
