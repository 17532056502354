import { Refresh } from "@mui/icons-material";
import {
  Typography,
  Button,
  Box,
  LinearProgress,
  Select,
  Option,
} from "@mui/joy";
import React from "react";
import Header from "../../../../components/layout/Header";
import { useStatsByNutrientStore } from "../../../../store/contexts/statsByNutrient.context";
import { NutrientOption } from "../../../../@types";
import { StatsTable } from "./components/StatsTable";

export const StatsByNutrient: React.FC = () => {
  const {
    loadingNutrients,
    loadingStats,
    nutrients,
    stats,
    selectedNutrient,
    setSelectedNutrient,
    handleFetchStatsData,
  } = useStatsByNutrientStore();

  const handleChangeNutrient = (
    event: React.SyntheticEvent | null,
    newValue: NutrientOption | null
  ) => {
    console.log("newValue: ", newValue);
    if (newValue) setSelectedNutrient(newValue);
  };

  return (
    <>
      <Header>
        <Typography level="body-lg" fontSize="xl">
          Nutrient Stats By Metabolite
        </Typography>
        <Button
          color="primary"
          startDecorator={<Refresh />}
          variant="outlined"
          sx={{ ml: "auto" }}
          onClick={() => {
            if (selectedNutrient?.id) handleFetchStatsData(selectedNutrient.id);
          }}
        >
          Refresh
        </Button>
      </Header>

      <div className="p-4 rounded">
        <div className="mb-4">
          <div className="flex gap-2 ">
            <div className="flex w-96 ">
              <Select
                variant="soft"
                placeholder="Select Nutrient"
                sx={{ width: "100%" }}
                onChange={handleChangeNutrient}
                value={selectedNutrient}
                disabled={loadingNutrients}
              >
                {nutrients?.map((nutrient) => (
                  <Option key={nutrient.id} value={nutrient}>
                    {nutrient.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          {loadingStats ? (
            <div className="">
              <LinearProgress />
            </div>
          ) : (
            <StatsTable stats={stats} />
          )}
        </Box>
      </div>
    </>
  );
};
